<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">General</span>
      </template>

      <b-card>

        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <b-col sm="6">
              <b-form-group
                label="Full Name"
                label-for="account-name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="full name"
                  rules="required"
                >
                  <b-form-input
                    v-model="options.fullName"
                    name="name"
                    placeholder="john doe"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="E-mail"
                label-for="account-e-mail"
              >
                <validation-provider
                  #default="{ errors }"
                  name="email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="options.email"
                    name="email"
                    placeholder="john@example.com"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
              </b-form-group>
              <small class="text-primary">*Note: When changed requires sign-in</small>
            </b-col>
            <b-col sm="6">
              <b-form-group
                label="Username"
                label-for="account-username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required"
                >
                  <b-form-input
                    disabled
                    v-model="options.username"
                    name="username"
                    placeholder="john-doe"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <small class="text-primary">*Note: Username cannot be changed after account is created!</small>
            </b-col>
            <!-- <b-col sm="6">
              <b-form-group
                label="Company"
                label-for="account-company"
              >
                <b-form-input
                  v-model="options.company"
                  name="company"
                  placeholder="Company name"
                />
              </b-form-group>
            </b-col> -->

            <!-- alert -->
            <b-col v-if="!options.emailVerified"
              cols="12"
              class="mt-75"
            >
              <b-alert
                show
                variant="warning"
                class="mb-50"
              >
                <h4 class="alert-heading">
                  Your email is not confirmed. Please check your inbox.
                </h4>
                <div class="alert-body">
                  <b-link class="alert-link" @click.prevent="sendEmailConfirmation">
                    Resend confirmation
                  </b-link>
                </div>
              </b-alert>
            </b-col>
            <!--/ alert -->

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 mr-1"
                @click.prevent="saveGeneralSettings"
              >
                Save changes
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                type="reset"
                class="mt-2"
                @click.prevent="resetForm"
              >
                Reset
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Change Password</span>
      </template>

      <b-card>
      <!-- form -->
      <b-form>
        <b-row>
          <!-- old password -->
          <b-col md="6">
            <b-form-group
              label="Old Password"
              label-for="account-old-password"
            >
             <validation-provider
                #default="{ errors }"
                name="old password"
                rules="required"
              >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-old-password"
                  v-model="passwordValueOld"
                  name="old-password"
                  :type="passwordFieldTypeOld"
                  placeholder="Old Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconOld"
                    class="cursor-pointer"
                    @click="togglePasswordOld"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ old password -->
        </b-row>
        <b-row>
          <!-- new password -->
          <b-col md="6">
            <b-form-group
              label-for="account-new-password"
              label="New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="new password"
                rules="required"
              >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-new-password"
                  v-model="newPasswordValue"
                  :type="passwordFieldTypeNew"
                  name="new-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconNew"
                    class="cursor-pointer"
                    @click="togglePasswordNew"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ new password -->

          <!-- retype password -->
          <b-col md="6">
            <b-form-group
              label-for="account-retype-new-password"
              label="Retype New Password"
            >
              <validation-provider
                #default="{ errors }"
                name="confirmed password"
                rules="required|confirmed:$newPasswordValue"
              >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="account-retype-new-password"
                  v-model="RetypePassword"
                  :type="passwordFieldTypeRetype"
                  name="retype-password"
                  placeholder="New Password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIconRetype"
                    class="cursor-pointer"
                    @click="togglePasswordRetype"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!--/ retype password -->

          <!-- buttons -->
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="updateUserPassword"
            >
              Save changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mt-1"
            >
              Reset
            </b-button>
          </b-col>
          <!--/ buttons -->
        </b-row>
      </b-form>
    </b-card>
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <!-- <b-tab> -->

      <!-- title -->
      <!-- <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Information</span>
      </template> -->

      <!-- <account-setting-information
        v-if="options.info"
        :information-data="options.info"
      /> -->
    <!-- </b-tab> -->

    <!-- social links -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Social</span>
      </template>

      <account-setting-social
        v-if="options"
        :social-data="options"
      />
    </b-tab> -->

    <!-- notification -->
    <!-- <b-tab>

      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Notifications</span>
      </template>

      <account-setting-notification
        v-if="options"
        :notification-data="options"
      />
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BTab, BTabs, BLink, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AccountSettingInformation from './AccountSettingInformation.vue'
import AccountSettingSocial from './AccountSettingSocial.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
import { getAuth, updateEmail, sendEmailVerification, updatePassword } from "firebase/auth";
import { getFirestore, setDoc, doc} from 'firebase/firestore';

const auth = getAuth()
const db = getFirestore()

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BLink,
    BInputGroup, 
    BInputGroupAppend,
    AccountSettingInformation,
    AccountSettingSocial,
    AccountSettingNotification,

    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple
  },
  data() {
    return {
      options: {},

      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      
      // validation rules
      required,
      email,
    }
  },
  created() {
    this.resetForm()
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    resetForm() {
      const user = auth.currentUser
      this.options = JSON.parse(localStorage.getItem('userData'))
      this.options.username = user.displayName
      this.options.emailVerified = user.emailVerified
    },
    sendEmailConfirmation()
    {
      sendEmailVerification(auth.currentUser)
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: `Email Verification Sent`,
          icon: 'mail',
          variant: 'info',
        }
      })
    },
    async updateUserPassword(){
      try {
        await updatePassword(auth.currentUser, this.newPasswordValue)
      }catch(error){
        console.log(error)

        // Show error
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Update Password Failed`,
            icon: 'user-x',
            variant: 'danger',
            text: 'Please logout and sign in back in to update password',
          },timeout: 5000
        })
      }
    },
    async updateProfile(){
      // Update the profile  
      await setDoc(doc(db, "profiles", auth.currentUser.displayName ), { fullName:this.options.fullName, email:auth.currentUser.email }, { merge: true })
      
      // Update the local storage
      localStorage.setItem('userData', JSON.stringify(this.options))

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Settings Updated',
          icon: 'EditIcon',
          variant: 'success',
        },
      })
    },
    async saveGeneralSettings(){
      // Did the user change their email
      if(auth.currentUser.email !==  this.options.email){
        try {
          const response = await updateEmail(auth.currentUser, this.options.email)
          sendEmailVerification(auth.currentUser)
          this.updateProfile()
        }catch(error){
          console.log(error)

          // Show error
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Update Settings Failed`,
              icon: 'user-x',
              variant: 'danger',
              text: 'Please logout and sign in back in to update email',
            },timeout: 5000
          })
        }
      }else{
        this.updateProfile()
      }
    }
  },
}
</script>
