<template>
  <b-card>
    <b-form>
      <b-row>
        <!-- Profile Connections -->
        <b-col
          cols="12"
          class="mt-1"
        >
          <div class="d-flex align-items-center mb-2">
            <feather-icon
              icon="LinkIcon"
              size="18"
            />
            <h4 class="mb-0 ml-75">
              Profile Connections
            </h4>
          </div>
          <b-row>

            <!-- linkedin profile -->
            <b-col
              md="6"
              cols="6"
              class="mb-1"
            >
              <div class="mb-1">
                <!-- <b-avatar
                  size="40"
                  :src="localOptions.connections.google.profileImg"
                /> -->
              </div>
              <b-card-text class="mb-0 font-weight-bold">
                Linkedin
                <!-- @{{ localOptions.connections.google.id }} -->
              <b-link>
                Coming Soon
              </b-link>
              </b-card-text>
            </b-col>
            <!--/ google profile -->
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BLink, BAvatar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
  },
  directives: {
    Ripple,
  },
  props: {
    socialData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify(this.socialData)),
    }
  },
  methods: {
  },
}
</script>
